import {Component, Input, OnInit} from '@angular/core';
import {environment} from '@env';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from '@core/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'ngx-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {

  @Input() DialogId: string;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  chatStatus: BehaviorSubject<string>;
  noMessagesPlaceholder: BehaviorSubject<string>;
  sendBtnPlaceholder: BehaviorSubject<string>;

  user = null;
  messages: any[] = [];
  occupants: any = {};

  @Input() callback: Function;

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
  ) {
    this.chatStatus = new BehaviorSubject<string>(translateService.instant(marker('Chat[Connecting]')));
    this.sendBtnPlaceholder = new BehaviorSubject<string>(this.translateService.instant(marker('Type a message')));
    this.noMessagesPlaceholder = new BehaviorSubject<string>(this.translateService.instant(marker('Loading')));
  }

  async ngOnInit() {
    await this.initChat();
  }

  async sendMessage(event) {
    // SEND MESSAGE
    const messageBody = event.message;
    const messageId = await ConnectyCube.chat.send(this.DialogId, {
      type: 'groupchat',
      body: messageBody,
      extension: {
        save_to_history: 1,
        dialog_id: this.DialogId,
      },
      markable: 1,
    });

    this.messages.push({
      id: messageId,
      text: messageBody,
      date: new Date(),
      reply: true,
      user: {
        name: this.user.full_name,
        avatar: this.user.avatar,
      },
    });
  }

  private async initChat() {
    await this.connect();

    await this.getOccupants();
    await this.getChatHistory();

    ConnectyCube.chat.onMessageListener = this.onMessageListener.bind(this);


    this.$loading.next(false);

  }


  private onMessageListener(userId, message) {
    // Check if message exist
    if (message.dialog === this.DialogId || this.messages.some(m => m.id === message.id)) {
      return;
    }
    this.$loading.next(true);
    const sound = new Audio('../../assets/sounds/notification.mp3');
    sound.play();
    const occupant = this.occupants[userId];
    this.messages.push({
      id: message.id,
      text: message.body,
      date: new Date(),
      reply: occupant.id === this.user.id,
      user: {
        name: occupant.full_name,
        avatar: occupant.avatar,
      },
    });
    this.$loading.next(false);
  }

  private async getChatHistory() {
    // GET MESSAGE HISTORY
    const messages = await ConnectyCube.chat.message.list({
      chat_dialog_id: this.DialogId,
      sort_desc: 'date_sent',
      limit: 9999,
      skip: 0,
    });
    for (const message of messages.items) {
      const sender = this.occupants[message.sender_id];
      const files = message.attachments.map(attachment => {
        return {
          url: attachment.url,
          type: attachment.type === 'image' ? 'image/jpeg' : attachment.type

        }
      });
      const isAttachment = !!files.length;
      this.messages.unshift({
        id: message.id,
        text: isAttachment ? "" : message.message,
        date: new Date(message.created_at),
        type: isAttachment ? 'file' : 'text',
        files: files,
        reply: message.sender_id === this.user.id,
        user: {
          name: sender?.full_name,
          avatar: sender?.avatar,
        },
      });
      this.noMessagesPlaceholder.next(this.translateService.instant(marker('No messages yet.')));

    }
  }

  private async connect() {
    const credentials = {
      appId: environment.chat.appId,
      authKey: environment.chat.authKey,
      authSecret: environment.chat.authSecret,
    };
    const config = {
      debug: {mode: 0},
    };

    ConnectyCube.init(credentials, config);

    const currentUser = this.authService.currentUser;
    const userCredentials = {
      userId: currentUser.me.chat_id,
      password: `Bearer ${currentUser.access_token}`,
    };

    await ConnectyCube.createSession();
    this.user = await ConnectyCube.login(userCredentials);
    const token = ConnectyCube.service.sdkInstance.session.token;
    await ConnectyCube.chat.connect({userId: this.user.id, password: token});
    this.chatStatus.next(ConnectyCube.chat.isConnected ?
      this.translateService.instant(marker('Chat[Connected]')) :
      this.translateService.instant(marker('Chat[Disconnected]')),
    );
  }

  private async getOccupants() {
    const occupants = await ConnectyCube.chat.dialog.getPublicOccupants(this.DialogId);
    for (const occupant of occupants.items) {
      const occupantUser = occupant.user;
      this.occupants[occupantUser.id] = occupantUser;
    }
  }
}
