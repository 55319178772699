<div class="header-container">
  <div class="logo-container">
    <a
      (click)="toggleSidebar()"
      [matRippleCentered]="true"
      [matRippleUnbounded]="true"
      class="sidebar-toggle"
      href="#"
      matRipple
    >
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    <a (click)="navigateHome()" class="logo" href="#">{{'Earnest Feber cPanel'|translate}}</a>
  </div>
</div>

<div class="header-container">
  <nb-select (selectedChange)="changeLanguage($event)" [selected]="currentLanguage" status="primary">
    <nb-option *ngFor="let language of languages" [value]="language.value"> {{ language.title}}</nb-option>
  </nb-select>
  <nb-actions size="medium">
    <nb-action [nbPopover]="notificationsComponent"
               class="control-item"
               icon="bell-outline"
               badgePosition="bottom right"
               badgeStatus="warning"
               [badgeText]="notification_count">
    </nb-action>
    <nb-action
      *ngIf="currentUser"
      [matRippleCentered]="true"
      [matRippleUnbounded]="true"
      class="user-action"
      matRipple>
      <nb-user [name]="currentUser.name"
               [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [picture]="">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
