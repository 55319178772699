import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {marker} from "@biesbjerg/ngx-translate-extract-marker";

export enum SectionEnum {
  ERNEST_FEABER = 1 << 0,
  HEPTA_TRANS = 1 << 1,
}

function checkValueHasFlag(statusId, enumValue) {
  return (statusId & enumValue) === enumValue;
}

@Injectable({providedIn: 'root'})
export class SectionService {

  constructor(public translate: TranslateService) {
  }


  toString(statusId) {
    const result = [];


    if (checkValueHasFlag(statusId, SectionEnum.HEPTA_TRANS)) {
result.push('HEPTA_TRANS');
}

    if (checkValueHasFlag(statusId, SectionEnum.ERNEST_FEABER)) {
result.push('ERNEST_FEABER');
}

    return result.join(', ');

  }

  getSectionStatusList() {

    return [
      {
        value: SectionEnum.HEPTA_TRANS,
        title: this.translate.instant(marker('HEPTA_TRANS')),
      },
      {
        value: SectionEnum.ERNEST_FEABER,
        title: this.translate.instant(marker('ERNEST_FEABER')),
      },
    ];
  }
}
