import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

import {NgControl} from '@angular/forms';

@Component({
  selector: 'ngx-img',
  templateUrl: './image.component.html',
})
export class ImageComponent implements OnInit, OnChanges {
  @Input() imgUrl: any = 0;
  @Input() title = '';
  @Input() disActiveValue = false;
  @Output() imageChanged: EventEmitter<any> = new EventEmitter();
  imageLoaded = false;

  constructor() {
  }

  ngOnInit() {
  }

  isInvalid(control: NgControl) {
    return control.invalid && control.touched;
  }

  uploadImage(imageInput) {
    if (window.FileReader) {
      if (!imageInput.files[0]) {
        return;
      }
      const file: File = imageInput.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.imageLoaded = true;
        this.imgUrl = reader.result.toString();

      });
      reader.readAsDataURL(file);
      this.imageChanged.emit(file);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
}
