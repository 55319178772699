import {ErrorHandler, Injectable, NgZone} from '@angular/core';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone) {
  }

  handleError(error) {
    // Sentry.captureException(error.originalError || error);
    this.zone.run(() => {
      console.error(error);
    });
  }
}

