export const environment = {
  production: false,
  apiUrl: 'https://dev-api.earnestfeber.com/api/v1/',
  baseUrl: 'http://127.0.0.1:4200',
  defaultLanguage: 'ar',
  firebase: {
    apiKey: 'AIzaSyDAjcjWqOUeptQJKnEqjHCSA6W2OQjWdW8',
    authDomain: 'earnest-feber.firebaseapp.com',
    projectId: 'earnest-feber',
    storageBucket: 'earnest-feber.appspot.com',
    messagingSenderId: '68083067888',
    appId: '1:68083067888:web:4d4c6fd86d191caf5c8b35',
    measurementId: 'G-W4G637XJ7C',
  },
  chat: {
    appId: 5561,
    authKey: 'g9NgeFaf8Gb4Ekp',
    authSecret: 'tmWMmJXEnV7MHaj',
  },
};
