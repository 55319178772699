import {Injectable} from '@angular/core';
import {AttachmentModel} from "@core/models/attachment.model";
import {TranslateService} from "@ngx-translate/core";
import {marker} from "@biesbjerg/ngx-translate-extract-marker";

@Injectable({providedIn: 'root'})
export class AttachmentStatusService {

  OPEN = 1;
  ACCEPTED = 2;
  REJECTED = 3;

  constructor(public translate: TranslateService) {
  }

  toString(statusId) {
    switch (statusId) {
      case 1:
        return this.translate.instant(marker('OPEN'));
      case 2:
        return this.translate.instant(marker('ACCEPTED'));
      case 3:
        return 'REJECTED';
    }
  }

  getAttachmentStatusList() {
    return [
      {
        value: 1,
        title: 'OPEN',
      },
      {
        value: 2,
        title: 'ACCEPTED',
      },
      {
        value: 3,
        title: 'REJECTED',
      },
    ];
  }

  isRejected(attachment: AttachmentModel) {
    return attachment?.status === this.REJECTED;
  }

  isAccepted(attachment: AttachmentModel) {
    return attachment?.status === this.ACCEPTED;
  }

}
