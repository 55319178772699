import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LocalDataSource} from 'ng2-smart-table';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {AppSettings} from '@config/app-settings.config';

@Injectable({providedIn: 'root'})
export class ServerDataSource extends LocalDataSource {

  lastRequestCount = 0;
  route = '';
  protected onUpdateStartedSource = new Subject<any>();

  constructor(protected http: HttpClient, appSettings: AppSettings, _route: string) {
    super();
    this.route = _route;
  }

  count(): number {
    return this.lastRequestCount;
  }

  onUpdateStarted(): Observable<any> {
    return this.onUpdateStartedSource.asObservable();
  }

  getElements(): Promise<any> {
    this.emitOnUpdateStarted(true);

    let params = new HttpParams().set('page', this.pagingConf['page'])
      .set('per_page', this.pagingConf['perPage']);

    // Sorting
    if (this.sortConf) {
      this.sortConf.forEach((fieldConf) => {
        if (fieldConf.direction.toUpperCase() === 'DESC') {
          params = params.append('sort', fieldConf.field);
        } else if (fieldConf.direction.toUpperCase() === 'ASC') {
          params = params.append('sort', '-' + fieldConf.field);
        }
      });
    }

    // Filters
    if (this.filterConf.filters) {
      this.filterConf.filters.forEach((fieldConf) => {
        if (fieldConf['search']) {
          params = params.append('filter[' + fieldConf['field'] + ']', fieldConf['search']);
        }
      });
    }
    return this.http.get(this.route, {params})
      .pipe(
        map(res => {
          this.lastRequestCount = +res['meta']['pagination']['total'];
          this.emitOnUpdateStarted(false);
          return res['data'];
        }),
      ).toPromise();

  }

  protected emitOnUpdateStarted(element: any) {
    this.onUpdateStartedSource.next(element);
  }

}
