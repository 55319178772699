import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {marker} from "@biesbjerg/ngx-translate-extract-marker";

export enum FieldEnum {

  TEXT = 1,
  FILE = 2,
  NUMBER = 3,
  SELECT = 4,
}

@Injectable({providedIn: 'root'})

export class FieldType {

  TEXT = 1;
  FILE = 2;
  NUMBER = 3;
  SELECT = 4;

  constructor(public translate: TranslateService) {
  }

  toString(statusId) {
    switch (statusId) {
      case 1:
        return this.translate.instant(marker('text'));
      case 2:
        return this.translate.instant(marker('file'));
      case 3:
        return this.translate.instant(marker('number'));
      case 4:
        return this.translate.instant(marker('select'));
    }
  }

  getFieldsList() {
    return [
      {
        value: 1,
        title: this.translate.instant(marker('text')),
      },
      {
        value: 2,
        title: this.translate.instant(marker('file')),
      },
      {
        value: 3,
        title: this.translate.instant(marker('number')),
      },
      {
        value: 4,
        title: this.translate.instant(marker('select')),
      },
    ];
  }

}
