import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({providedIn: 'root'})
export class NotificationTypeService {

  MESSAGE_FROM_ADMIN = 1;
  GENERAL = 2;
  ADD_JOIN_US_ORDER = 4;
  ACCEPT_JOIN_US_ORDER = 3;
  ADD_FEEDBACK = 5;
  ADD_ORDER_OFFER = 6;
  UPDATE_ORDER_OFFER = 7;
  ACCEPT_ORDER_OFFER = 8;
  ADD_ORDER = 9;
  REJECT_ORDER_OFFER = 10;
  CONFIRMED_ORDER_DELIVERY_BY_USER = 11;
  ORDER_DELIVERED_BY_EXPERT = 12;
  ORDER_REJECTED_BY_ADMIN = 13;
  ORDER_APPROVED = 14;

  constructor(private router: Router,
              private translateService: TranslateService) {
  }

  toString(typeId) {
    switch (typeId) {
      case this.MESSAGE_FROM_ADMIN:
        return this.translateService.instant(marker('message from admin'));
      case this.GENERAL:
        return this.translateService.instant(marker('general'));
      case this.ADD_JOIN_US_ORDER:
        return this.translateService.instant(marker('add join us order'));
      case this.ACCEPT_JOIN_US_ORDER:
        return this.translateService.instant(marker('accept join us order'));
      case this.ADD_FEEDBACK:
        return this.translateService.instant(marker('add feedback'));
      case this.ADD_ORDER_OFFER:
        return this.translateService.instant(marker('add order offer'));
      case this.UPDATE_ORDER_OFFER:
        return this.translateService.instant(marker('update order offer'));
      case this.ACCEPT_ORDER_OFFER:
        return this.translateService.instant(marker('accept order offer'));
      case this.ADD_ORDER:
        return this.translateService.instant(marker('add order'));
      case this.REJECT_ORDER_OFFER:
        return this.translateService.instant(marker('reject order offer'));
      case this.ORDER_APPROVED:
        return this.translateService.instant(marker('order approved'));
      case this.CONFIRMED_ORDER_DELIVERY_BY_USER:
        return this.translateService.instant(marker('confirmed order delivery by user'));
      case this.ORDER_DELIVERED_BY_EXPERT:
        return this.translateService.instant(marker('order delivered by expert'));
      case this.ORDER_REJECTED_BY_ADMIN:
        return this.translateService.instant(marker('order rejected by admin'));
    }
  }

  handleClick(notification) {
    const data = notification.data;
    const typeId = parseInt(data.type);

    switch (typeId) {
      case this.MESSAGE_FROM_ADMIN:
        return this.router.navigateByUrl('users');
      case this.GENERAL:
        return 'general';
      case this.ADD_JOIN_US_ORDER:
        return this.router.navigateByUrl('/join-us-orders/' + data.join_us_order_id);
      case this.ACCEPT_JOIN_US_ORDER:
        return this.router.navigateByUrl('/join-us-orders/' + data.join_us_order_id);
      case this.ADD_FEEDBACK:
        return this.router.navigateByUrl('/feedbacks/' + data.feedback_id);
      case this.ADD_ORDER_OFFER:
        return 'add order offer';
      case this.UPDATE_ORDER_OFFER:
        return 'update order offer';
      case this.ACCEPT_ORDER_OFFER:
        return 'accept order offer';
      case this.ADD_ORDER:
        return this.router.navigateByUrl('/orders/' + data.order_id);
      case this.REJECT_ORDER_OFFER:
        return 'reject order offer';
    }
  }

}
