import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, first, map, mergeMap} from 'rxjs/operators';
import {environment} from '@env';
import {AuthService} from '@core/services/auth.service';
import {TokenModel} from '@core/models/token.model';


const apiUrl = environment.apiUrl;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  private static appendApiUrl<A>(request: HttpRequest<any>, user: A) {
    if (!request.url.endsWith('.json')) {
      request = request.clone({url: apiUrl + request.url});
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const user = this.authService.user.value;

    // Append API URL
    request = TokenInterceptor.appendApiUrl(request, user);

    // Request not requires access token
    if (!user ||
      request.url.includes('/auth/refresh') ||
      request.url.includes('/auth/login') ||
      request.url.includes('/auth/logout')
    ) {
return next.handle(request);
}

    if (this.authService.needRefresh()) {
      // console.log("this.authService.needRefresh()");
      return this.handleRefreshToken(request, next);
    }
    // Handle response
    return next.handle(this.addAuthHeader(request, user)).pipe(map(value => value), catchError(error => this.handleResponseError(error, request, next)));
  }

  addAuthHeader(request: HttpRequest<any>, user: TokenModel): any {
    if (user && user.access_token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.access_token}`,
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });
    }

    return request;
  }

  handleResponseError(error, request?, next?) {
    // console.log('Intersptor', error);
    // Business error
    if (request.url.includes('/auth/logout')) {
      return;
    }

    switch (error.status) {
      case 422:
        const errors = this.getErrorMessage422(error).join("<br>");
        return throwError(errors);
      case 401:
        return this.handleRefreshToken(request, next);
      case 403: // Show message
        // Logout
        // this.authService.logout();
        // Too Many Requests
        break;
      case 429:
        // console.log('error' + error.statusText);
        break;
      case 500:
        // console.log('error' + error.status);
        break;
      case 503:
        // console.log('error' + error.status);
        break;
    }
    const errorMessage = error.message || error.status_code;

    return throwError(errorMessage);
  }

  getErrorMessage422(errorMessages) {

    let listErrors: any[];
    listErrors = [];
    let errors: any[];

    let mapErrors: Map<string, unknown>;
    mapErrors = new Map(Object.entries(errorMessages.error.data));
    let errorsMessage = '';
    mapErrors.forEach((value, key) => {
      errors = Array.prototype.slice.call(value);
      errors.forEach(val => {
        listErrors.push(val);
      });
    });
    mapErrors.forEach((value, key) => {
      errorsMessage += value + '  ';
      errorsMessage += '<br>';
    });
    return listErrors;
  }

  private handleRefreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.refreshToken().pipe(
      first(),
      mergeMap(() => {
        const user = this.authService.user.value;
        request = this.addAuthHeader(request, user);
        return next.handle(request);
      }),
      catchError(e => {
        if (e.status !== 401) {
          this.handleResponseError(e);
        } else {
          this.authService.unAuth();
        }
        return throwError(e);
      }),
    );
  }


}
