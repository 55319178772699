<div *ngxPermissionsOnly="['SHOW_FIELD']">
  <nb-card>
    <nb-card-header>
      <div class="row">
        <h4 class="">{{"Fields List" | translate}}</h4>
      </div>
    </nb-card-header>

    <nb-card-body [nbSpinner]="loading$ | async">
      <ng2-smart-table (userRowSelect)="onRowSelect($event)"
                       [settings]="settings"
                       [source]="source"></ng2-smart-table>
      <hr>
    </nb-card-body>
  </nb-card>
</div>

