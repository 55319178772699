import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {catchError, tap} from 'rxjs/operators';
import {Response} from '@core/models/response.model';
import {NgxPermissionsService} from 'ngx-permissions';
import {ToastService} from '@core/services/shared/toast.service';
import {TokenModel} from '@core/models/token.model';

@Injectable({providedIn: 'root'})
export class NotificationService {
  constructor(private http: HttpClient,
              private router: Router,
              private permissionsService: NgxPermissionsService,
              private _toastService: ToastService) {
  }

  notifications(page, per_page) {
    const params = new HttpParams().set('page', page).set('per_page', per_page);
    return this.http.get('me/notifications', {params});
  }

  notificationsCount() {
    return this.http.get('home');
  }

  markAsRead(id) {
    return this.http.post(`notifications/${id}/mark-as-read`, []);
  }

  markAllAsRead() {
    return this.http.post(`me/mark-all-notifications-as-read`, []);
  }
}
