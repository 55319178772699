import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';

import {ServerDataSource} from '@core/data/server.data-source';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {ToastService} from '@core/services/shared/toast.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '@config/app-settings.config';
import {HttpClient} from '@angular/common/http';
import {AttachmentModel} from '@core/models/attachment.model';
import {OfferStatusService} from '@core/enum/offer-status.service';

@Component({
  selector: 'ngx-offers-list',
  templateUrl: './offers-list.component.html',
})
export class OffersListComponent implements OnInit, OnChanges {
  @Input() Id: string;
  @Input() type: string;
  loading$ = new BehaviorSubject<boolean>(false);
  settings = {
    mode: 'external',
    hideSubHeader: false,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    noDataMessage: '',
    columns: {},
    columns_en: {
      id: {
        title: '#',
        width: '30px',
        type: 'number',
      },
      user: {
        title: 'owner name',
        filter:false,
        valuePrepareFunction: (value) => value.name
      },
      user_phone: {
        title: 'owner phone',
        filter: false,
        valuePrepareFunction: (value,parent) => parent.user.phone
      },
      price: {
        title: 'price',
        type: 'number',
      },
      status: {
        title: 'status',
        type: 'number',
        editable: false,
        editor: {
          type: 'list',
          config: {
            list: '',
          },
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: this.offerStatus.getOfferStatusList(),
          },
        },
        valuePrepareFunction: (value) => this.offerStatus.toString(value),
      },

    },
    columns_ar: {
      id: {
        title: '#',
        width: '30px',
        type: 'number',
      },
      user: {
        title: 'اسم المستخدم',
        filter:false,
        valuePrepareFunction: (value) => value.name
      },
      user_phone: {
        title: 'رقم هاتف المستخد',
        filter: false,
        valuePrepareFunction: (value,parent) => parent.user.phone
      },
      link: {
        title: 'السعر',
        type: 'number',
      },
      status: {
        title: 'الحالة',
        type: 'number',
        editable: false,
        editor: {
          type: 'list',
          config: {
            list: '',
          },
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'اختر',
            list: this.offerStatus.getOfferStatusList(),
          },
        },
        valuePrepareFunction: (value) => this.offerStatus.toString(value),
      },

    },
    action: {},
  };
  source: ServerDataSource;
  orderAttachmentsList = new BehaviorSubject<AttachmentModel[]>([]);

  constructor(private _toastService: ToastService,
              private http: HttpClient,
              private router: Router,
              protected location: Location,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog,
              private offerStatus: OfferStatusService,
              private appSettings: AppSettings,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    // Order Attachments
    this.loading$.next(true);
    this.source = new ServerDataSource(this.http, this.appSettings, `${this.type}/${this.Id}/offers`);
    this.source.onUpdateStarted().subscribe(e => {
      this.loading$.next(false);
    });
    //  Load current language
    this.updateColumns();
    this.translateService.onLangChange.subscribe(() => {
      this.updateColumns();
    });
  }

  updateColumns() {
    this.settings.noDataMessage = this.translateService.instant(marker('No Data Found'));
    this.settings.columns = this.settings['columns_' + this.translateService.currentLang];
    this.settings = Object.assign({}, this.settings);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  onRowSelect(event) {
    this.router.navigateByUrl('/offers/' + event.data.id);
  }
}
