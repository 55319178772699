import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-auth',
  template: `
    <router-outlet></router-outlet>
  `,

})
export class AuthComponent implements OnInit {

  ngOnInit(): void {
  }

}

// experts: Experts;
