import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {marker} from "@biesbjerg/ngx-translate-extract-marker";
import {ToastService} from "@core/services/shared/toast.service";
import {NotificationService} from "@core/services/notification/notification.service";
import {NotificationTypeService} from "@core/enum/notification-type.service";

@Injectable({providedIn: 'root'})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private notificationTypeService: NotificationTypeService,
    private toastService: ToastService,
    private angularFireMessaging: AngularFireMessaging
  ) {
    if (navigator && navigator.serviceWorker)
      navigator.serviceWorker.addEventListener('message', this.onReceiveMsg.bind(this));

  }


  onReceiveMsg(event) {
    // console.log('onReceiveMsg');
    // console.log(event);
    if (event.data && event.data.isFirebaseMessaging) {
      const data = event.data;
      let messageType = data.messageType;
      switch (messageType) {
        case "notification-clicked":
          this.notificationTypeService.handleClick(data);
          break;
        case "push-received":
          this.toastService.showToast('info', data.notification.title, data.notification.body)
            .onClick().subscribe(() => this.notificationTypeService.handleClick(data));
          break;
      }
    }
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        localStorage.setItem('fcmToken', token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      },
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        // console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      });
  }
}
