<nb-chat [nbSpinner]="$loading | async"
         [noMessagesPlaceholder]="noMessagesPlaceholder | async"
         [title]="chatStatus | async" size="medium">

  <nb-chat-message
    *ngFor="let msg of messages"
    [avatar]="msg.user.avatar"
    [date]="msg.date"
    [files]="msg.files"
    [latitude]="msg.latitude"
    [longitude]="msg.longitude"
    [message]="msg.text"
    [quote]="msg.quote"
    [reply]="msg.reply"
    [sender]="msg.user.name"
    [type]="msg.type"
  >
  </nb-chat-message>
  <nb-chat-form [messagePlaceholder]="sendBtnPlaceholder|async" (send)="sendMessage($event)"
                [dropFiles]="false"></nb-chat-form>
</nb-chat>
