import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'ngx-http-error',
  templateUrl: 'http-error.component.html',
})
export class HttpErrorComponent implements OnInit, OnChanges {
  @Input() error: any;

  title: string = null;
  errors: string[] = [];

  errors_translation = {
    404: marker('The model is not found.'),
    422: marker('The given data was invalid.'),
    500: marker('Something went wrong'),
  };

  defaultError = 500;

  ngOnInit() {
    this.parseError();
  }

  onClose() {
    this.error = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.error) {
      this.parseError();
    }
  }

  private parseError() {
    const statusCode = this.error.status_code;
    this.title = this.errors_translation[statusCode] || this.errors_translation[this.defaultError];
    this.errors = [];

    for (const error in this.error.errors) {
      if (this.error.errors.hasOwnProperty(error)) {
        this.errors = [...this.errors, ...this.error.errors[error]];
      }
    }
  }
}
