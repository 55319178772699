import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {catchError, tap} from 'rxjs/operators';
import {Response} from '@core/models/response.model';
import {NgxPermissionsService} from 'ngx-permissions';
import {ToastService} from '@core/services/shared/toast.service';
import {TokenModel} from '@core/models/token.model';
import {v4 as uuidv4} from 'uuid';

@Injectable({providedIn: 'root'})
export class AuthService {
  user = new BehaviorSubject<TokenModel>(null);
  public currentUserSubject: BehaviorSubject<TokenModel>;
  public currentUserAtt: Observable<TokenModel>;

  constructor(private http: HttpClient,
              private router: Router,
              private permissionsService: NgxPermissionsService,
              private _toastService: ToastService) {

    const currentItemStorage = localStorage.getItem('earnestfeber_currentUser');
    this.currentUserSubject = new BehaviorSubject<TokenModel>(JSON.parse(currentItemStorage));
    this.currentUserAtt = this.currentUserSubject.asObservable();
  }

  public get currentUser(): TokenModel {
    return this.user.value;
  }

  public get currentUserValue(): TokenModel {
    return this.currentUserSubject.value;
  }

  public get isLoggedIn(): boolean {
    return this.user.value !== null;
  }

  notifications(page, per_page) {
    const params = new HttpParams().set('page', page).set('per_page', per_page);
    return this.http.get('me/notifications', {params});
  }

  login(phone, password) {
    const udid = uuidv4();
    localStorage.setItem('uuid', udid);
    const fcm_token = localStorage.getItem('fcmToken') ? localStorage.getItem('fcmToken') : '';

    const body = {
      phone,
      password,
      fcm_token,
      udid,
    };
    return this.http.post<Response<TokenModel>>('auth/login', body).pipe(
      tap(response => {
          this.router.navigate(['/']);
          this.handleAuthentication(response.data);
        },
      ),
    );
  }

  loadPermissions(permissions) {
    const Permissions = [];
    for (let i = 0; i < permissions?.length; i++) {
      Permissions.push(permissions[i].name);
    }
    this.permissionsService.loadPermissions(Permissions);
  }

  logout() {
    const user = this.user.value;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${user.refresh_token}`,
    });

    return this.http.get<Response<TokenModel>>(`auth/logout?access_token=${user.access_token}`, {
      headers,
    }).subscribe(
      data => {
        this._toastService.showToast('success', 'Success', 'Sign out successfully');
        this.unAuth();
      },
      error => {
        this.unAuth();
      },
    );
  }

  updateProfileLanguage(language) {
    const httpHeaders = new HttpHeaders({
      'Content-type': 'application/json',
    });
    return this.http.put('auth/update', {language}, {headers: httpHeaders});
  }

  unAuth() {
    localStorage.removeItem('earnestfeber_currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['auth/login']);
  }

  autoLogin() {
    const userData: TokenModel = JSON.parse(localStorage.getItem('earnestfeber_currentUser'));
    if (!userData) {
      {
        this.user.next(null);
        return;
      }
    }
    if (userData.access_token) {
      this.loadPermissions(userData.me?.permissions);
      this.user.next(userData);
    }
  }

  refreshToken() {
    const currentUser = this.currentUser;
    const token = currentUser.access_token;
    // console.log("access_token");
    // console.log(token);
    // console.log("refresh request ............");
    const headers = new HttpHeaders({
      Authorization: `Bearer ${currentUser.refresh_token}`,
    });
    return this.http.get<Response<TokenModel>>(`auth/refresh?access_token=${token}`, {
      headers,
    }).pipe(
      tap((response) => {
          this.handleAuthentication(response.data);
        },
      ),
      catchError(err => {
        this.unAuth();
        throw err;
      }),
    );
  }

  needRefresh() {
    const currentUser = this.currentUser;
    const now = new Date();
    const accessExpiresAt = new Date(currentUser.access_expires_at);
    const refreshExpiresAt = new Date(currentUser.refresh_expires_at);
    // Access token expiry in future
    if (now < accessExpiresAt) {
return false;
}

    // Refresh token expiry in future
    if (now < refreshExpiresAt) {
return true;
} else
      // Refresh token expired
      {
this.unAuth();
}
  }

  private handleAuthentication(token: TokenModel) {
    this.loadPermissions(token.me?.permissions);
    this.user.next(token);
    localStorage.setItem('earnestfeber_currentUser', JSON.stringify(token));
  }

  private autoLogout(number: number) {

  }
}
