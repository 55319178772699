import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {marker} from "@biesbjerg/ngx-translate-extract-marker";

@Injectable({providedIn: 'root'})
export class OfferStatusService {

  PENDING = 1;
  ACCEPTED = 2;
  REJECTED = 3;

  constructor(public translate: TranslateService) {
  }

  toString(statusId) {
    switch (statusId) {
      case this.PENDING:
        return this.translate.instant(marker('Pending'));
      case this.ACCEPTED:
        return this.translate.instant(marker('Accepted'));
      case this.REJECTED:
        return this.translate.instant(marker('Rejected'));
    }
  }

  getOfferStatusList() {
    return [
      {
        value: this.PENDING,
        title: this.translate.instant(marker('Pending')),
      },
      {
        value: this.REJECTED,
        title: this.translate.instant(marker('Rejected')),
      },
      {
        value: this.ACCEPTED,
        title: this.translate.instant(marker('Accepted')),
      },
    ];
  }

}
