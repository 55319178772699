import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SectionEnum} from '@core/enum/section.service';
import {NgControl} from '@angular/forms';
import {UserStatusService} from '@core/enum/user-status.service';

@Component({
  selector: 'ngx-user-section-status-checkbox',
  template: `
    <div>

      <div class="form-group row">
        <label for="selectStatus" class="label col-sm-3 col-form-label">{{'section' | translate}}*</label>
        <div class="col-sm-9">
          <nb-checkbox id="selectStatus"
                       required
                       name="section"
                       #status="ngModel"
                       [disabled]="disActiveValue"
                       [(ngModel)]="hebtaChecked"
                       (checkedChange)="onHebtaCheckedChange()">{{"Hebta" | translate}}</nb-checkbox>
          &nbsp;
          <nb-checkbox id="selectStatus"
                       required
                       name="section"
                       #status="ngModel"
                       [(ngModel)]="earnestChecked"
                       [disabled]="disActiveValue"
                       (checkedChange)="oneErnestCheckedChange()">{{"Earnest" |translate}}</nb-checkbox>
          <ng-container *ngIf="isInvalid(status)">
            <p class="caption status-danger" *ngIf="status.errors?.required">
              {{"The section field is required" }}.
            </p>
          </ng-container>

        </div>
      </div>
    </div>
  `,
})
export class UserSectionStatusCheckboxComponent implements OnInit, OnChanges {
  hebtaChecked: boolean;
  earnestChecked: boolean;
  @Input() value: any = 0;
  @Input() disActiveValue = false;
  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor(private _userststus: UserStatusService) {
  }

  ngOnInit() {
  }

  onHebtaCheckedChange() {
    this.hebtaChecked = !this.hebtaChecked;
    this.onCheckedChange();
  }

  oneErnestCheckedChange() {
    this.earnestChecked = !this.earnestChecked;
    this.onCheckedChange();
  }

  onCheckedChange() {
    let value = 0;
    if (this.hebtaChecked) {
value |= 1;
}
    if (this.earnestChecked) {
value |= 2;
}
    console.warn(`checked(${value})`);
    this.save.emit(value);
  }

  isInvalid(control: NgControl) {
    return control.invalid && control.touched;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hebtaChecked = this._userststus.checkValueHasFlag(this.value, SectionEnum.HEPTA_TRANS);
    this.earnestChecked = this._userststus.checkValueHasFlag(this.value, SectionEnum.ERNEST_FEABER);
    // console.log(changes);
    // console.log(this.value);
    // console.log(this.value);
    // console.log(this.hebtaChecked);
    // console.log(this.earnestChecked);
  }
}
