import {AuthRoutingModule} from '@pages/auth/auth-routing.module';
import {Injectable, NgModule, Optional, SkipSelf} from '@angular/core';
import {AuthComponent} from '@pages/auth/auth.component';
import {LoginComponent} from '@pages/auth/login/login.component';
import {EnsureModuleLoadedOnceGuard} from '@core/guards/EnsureModuleLoadedOnceGuard';
import {SharedModule} from '@core/shared.module';
import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';

@Injectable({
  providedIn: 'root',
})
@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
  ],
  imports: [
    AuthRoutingModule,
    SharedModule,
    NgxMatIntlTelInputModule,
  ],
})
export class AuthModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    super(parentModule);
  }
}
