import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTreeModule} from '@angular/material/tree';
import {MatDialogModule} from '@angular/material/dialog';
import {PaginatorComponent} from './components/paginator/paginator.component';
import {MatPaginatorModule} from '@angular/material/paginator';


import {
  NbActionsModule,
  NbAlertModule, NbBadgeModule,
  NbButtonModule,
  NbCardModule, NbChatModule,
  NbCheckboxModule,
  NbContextMenuModule, NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbRadioModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbUserModule,
} from '@nebular/theme';
import {NbAuthModule} from '@nebular/auth';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {NgxPermissionsModule} from 'ngx-permissions';
import {CapitalizePipe, NumberWithCommasPipe, PluralPipe, RoundPipe, TimingPipe} from './pipes';
import {SwitcherComponent} from './components/switcher/switcher.component';
import {FooterComponent} from './components/footer/footer.component';
import {SearchInputComponent} from './components/search-input/search-input.component';
import {HeaderComponent} from './components/header/header.component';
import {OneColumnLayoutComponent} from './components/one-column/one-column.layout';
import {ThreeColumnsLayoutComponent} from './components/three-columns/three-columns.layout';
import {TwoColumnsLayoutComponent} from './components/two-columns/two-columns.layout';
import {NbSecurityModule} from '@nebular/security';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {EnumToArrayPipe} from './pipes/enum-to-array.pipe';
import {ErrorDialogComponent} from './components/error-dialog/error-dialog.component';
import {ConfirmationDialogComponent} from '@core/components/confirmation-dialog/confirmation-dialog.component';
import {StatusCheckboxComponent} from '@core/components/status-checkbox/status-checkbox.component';
import {RolesStatusCheckboxComponent} from '@core/components/roles-status-checkbox/roles-status-checkbox.component';
import {StatusCardComponent} from '@core/components/status-card/status-card.component';
import {
  UserSectionStatusCheckboxComponent,
} from '@core/components/user-section-status-checkbox/user-section-status-checkbox.component';
import {TranslateModule} from '@ngx-translate/core';
import {OffersListComponent} from '@core/components/offers-list/offers-list.component';
import {HttpErrorComponent} from '@core/components/http-error/http-error.component';
import {ImageComponent} from '@core/components/image-container/image.component';
import {ServiceFieldsListComponent} from '@core/components/service-field/service-fields-list.component';
import {CoursesListComponent} from '@core/components/courses-list/courses-list.component';
import {ChatComponent} from '@core/components/chat-component/chat.component';
import {NotificationListComponent} from '@core/components/notification-list/notification-list.component';
import {NotificationComponent} from '@core/components/notification-component/notification.component';
import {
  ServicesSelectionTreeComponent
} from '@core/components/services-selection-tree/services-selection-tree.component';
import {DatepickerShowcaseComponent} from "@core/components/datepicker/DatepickerShowcase.component";

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EnumToArrayPipe,
];
const COMPONENTS = [
  PaginatorComponent,
  RolesStatusCheckboxComponent,
  StatusCheckboxComponent,
  UserSectionStatusCheckboxComponent,
  ConfirmationDialogComponent,
  ErrorDialogComponent,
  SwitcherComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  StatusCardComponent,
];
const NB_MODULES = [
  NbChatModule,
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    OffersListComponent,
    HttpErrorComponent,
    ImageComponent,
    DatepickerShowcaseComponent,
    ServiceFieldsListComponent,
    CoursesListComponent,
    NotificationListComponent,
    ChatComponent,
    NotificationComponent,
  ],
  imports: [
    ...NB_MODULES,
    CommonModule,
    RouterModule,
    FormsModule,
    MatDialogModule,
    MatPaginatorModule,
    MatCardModule,
    MatToolbarModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTreeModule,
    NbRadioModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbAuthModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatNativeDateModule,
    DragDropModule,
    Ng2SmartTableModule,
    NbCardModule,
    NbStepperModule,
    NbIconModule,
    NbSelectModule,
    NbSpinnerModule,
    NgxPermissionsModule,
    TranslateModule,
    NbListModule,
    NbPopoverModule,
    NbBadgeModule,
    NbDatepickerModule,
  ],
  exports: [
    ...COMPONENTS,
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatPaginatorModule,
    MatCardModule,
    MatToolbarModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTreeModule,
    NbRadioModule,
    FormsModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbAuthModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatNativeDateModule,
    DragDropModule,
    Ng2SmartTableModule,
    NbCardModule,
    NbStepperModule,
    NbIconModule,
    NbSelectModule,
    NbSpinnerModule,
    NgxPermissionsModule,
    TranslateModule,
    ...PIPES,
    OffersListComponent,
    HttpErrorComponent,
    ImageComponent,
    DatepickerShowcaseComponent,
    ServiceFieldsListComponent,
    CoursesListComponent,
    ChatComponent,
    NotificationListComponent,
  ],

})

export class SharedModule {
}
