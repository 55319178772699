import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class StatusService {

  disabled = {
    value: 'disabled',
    title: 'Disabled',
  };

  active = {
    value: 'active',
    title: 'Active',
  };

  constructor() {
  }

  get options() {
    return [
      this.active,
      this.disabled,
    ];
  }

  isActive(status = '') {
    return status.toLowerCase() === this.active.value;
  }

  isDisabled(status = '') {
    return status.toLowerCase() === this.disabled.value;
  }

  getTitle(status = '') {
    if (this.isActive(status)) {
      return this.active.title;
    } else if (this.isDisabled(status)) {
      return this.disabled.title;
    } else {
      return '';
    }

  }

  boolToStatus(checked: boolean) {
    if (checked) {
      return this.active.value;
    } else {
      return this.disabled.value;
    }

  }

}
