<nb-card>
  <nb-card-header>{{data.name}}</nb-card-header>
  <nb-card-body>
    <p>{{data.message}}</p>
  </nb-card-body>
  <nb-card-footer>
    <button (click)="onClick(false)" class="cancel" nbButton status="danger">{{'Cancel' | translate }}"</button>
    <button (click)="onClick(true)" nbButton status="success">{{'Submit' | translate }}</button>
  </nb-card-footer>
</nb-card>
