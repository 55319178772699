import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {NbAuthModule, NbPasswordAuthStrategy} from '@nebular/auth';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {of as observableOf} from 'rxjs';

import {EnsureModuleLoadedOnceGuard} from './guards/EnsureModuleLoadedOnceGuard';
import {AnalyticsService, LayoutService} from './utils';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {TokenInterceptor} from './interceptors/token-interceptor.service';
import {ErrorHandlerModule} from '@core/errors/error-handler.module';
import {LanguageInterceptor} from '@core/interceptors/language.interceptor';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
      }),
    ],
    forms: {
      login: {},
      register: {},
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
];

@NgModule({
  imports: [
    ErrorHandlerModule,
  ],
  exports: [
    NbAuthModule,

  ],
  declarations: [],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
    // provider used to create fake backend
    HttpClient,
  ],

})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
