import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'ngx-status-card',
  styleUrls: ['./status-card.component.scss'],
  template: `
    <nb-card (click)="click()">
      <div class="icon-container">
        <div class="icon status-primary">
          <i [ngClass]="iconClass"></i>
        </div>
      </div>

      <div class="details">
        <div class="title h5">{{ title }}</div>
        <div class="status paragraph-2">Click to modify</div>
      </div>
    </nb-card>
  `,
})
export class StatusCardComponent {

  @Input() title: string;
  @Input() type: string;
  @Input() iconClass: string;
  @Input() path = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  click() {
    this.router.navigate([this.path], {relativeTo: this.route});
  }
}
