import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from '@core/utils/analytics.service';
import {AuthService} from '@core/services/auth.service';
import {I18nService} from '@core/services/i18n.service';
import {MessagingService} from '@core/services/messaging.service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(private analytics: AnalyticsService,
              private authService: AuthService,
              private i18nService: I18nService,
              private messagingService: MessagingService,
  ) {
  }

  ngOnInit() {
    this.authService.autoLogin();
    this.analytics.trackPageViews();
    this.i18nService.init();

    this.messagingService.requestPermission();
    // this language will be used as a fallback when a translation isn't found in the current language
    // this.translateService.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // switch (this.authService.currentUserValue.language) {
    //   case this.userLanguage.EN:
    //     this.translateService.use('en');
    //     this.nbLayoutDirectionService.setDirection(NbLayoutDirection.LTR);
    //     break;
    //   case this.userLanguage.AR:
    //     this.translateService.use('ar');
    //     this.nbLayoutDirectionService.setDirection(NbLayoutDirection.RTL);
    //     break;
    // }
  }


}
