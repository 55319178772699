import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {marker} from "@biesbjerg/ngx-translate-extract-marker";

export enum UserTypeEnum {
  None = 0,
  NORMAL = 0,
  EXPERT = 1 << 0,
  MARKETER = 1 << 1,
  SPECIAL = 1 << 2,
  TEACHER = 1 << 3,
  DISABLED = 1 << 4,
}

@Injectable({providedIn: 'root'})
export class UserStatusService {

  constructor(private translate: TranslateService) {
  }


  checkValueHasFlag(statusId, enumValue) {
    return (statusId & enumValue) === enumValue;
  }

  toString(statusId) {
    const result = [];

    if (statusId === UserTypeEnum.NORMAL)
      return 'NORMAL';

    if (this.checkValueHasFlag(statusId, UserTypeEnum.EXPERT))
      result.push('EXPERT');

    if (this.checkValueHasFlag(statusId, UserTypeEnum.MARKETER))
      result.push('MARKETER');

    if (this.checkValueHasFlag(statusId, UserTypeEnum.SPECIAL))
      result.push('SPECIAL');

    if (this.checkValueHasFlag(statusId, UserTypeEnum.TEACHER))
      result.push('TEACHER');

    if (this.checkValueHasFlag(statusId, UserTypeEnum.DISABLED))
      result.push('DISABLED');

    return result.join(', ');

  }

  getUserStatusList() {

    return [
      {
        value: 0,
        title: this.translate.instant(marker('Normal')),
      },
      {
        value: 1,
        title: this.translate.instant(marker('EXPERT')),
      },
      {
        value: 2,
        title: this.translate.instant(marker('MARKETER')),
      },
      {
        value: 4,
        title: this.translate.instant(marker('SPECIAL')),
      },

      {
        value: 8,
        title: this.translate.instant(marker('TEACHER')),
      },
      {
        value: 16,
        title: this.translate.instant(marker('DISABLED')),
      },
    ];

  }
}

