import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class FieldService {
  constructor(private http: HttpClient) {
  }

  getFieldsList(page, per_page) {
    const params = new HttpParams().set('page', page).set('per_page', per_page);
    return this.http.get('fields', {params});
  }

  getFiltersFieldsList(page, per_page, filter) {
    const params = new HttpParams()
      .set('page', page)
      .set('per_page', per_page)
      .set('filter[name]', filter);
    return this.http.get('fields', {params});
  }

  getFieldById(id) {
    return this.http.get(`fields/${id}`);
  }

  addField(user) {
    return this.http.post('fields', user);
  }

  deleteField(id: string) {
    return this.http.delete(`fields/${id}`);
  }

  editField(field_id, field) {
    return this.http.post(`fields/${field_id}`, field);
  }

}
