import {Component, Input} from '@angular/core';
import {NotificationTypeService} from '@core/enum/notification-type.service';
import {first} from 'rxjs/operators';
import {NotificationService} from '@core/services/notification/notification.service';
import {NotificationModel} from '@core/models/notification.model';
import moment from 'moment';

@Component({
  selector: 'ngx-notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss'],
})
export class NotificationComponent {


  @Input() notification: NotificationModel = null;

  constructor(private notificationTypeService: NotificationTypeService,
              private notificationService: NotificationService) {
  }

  getDate(): string {
    return moment(this.notification.created_at).fromNow();
  }

  getNotificationTitle(): string {
    return this.notificationTypeService.toString(this.notification.data.type);
  }

  handleNotificationClick() {
    this.notificationTypeService.handleClick(this.notification);
    this.notificationService.markAsRead(this.notification.id).pipe(first())
      .subscribe(
        data => {
        },
        error => {
        });

  }


  isRead() {
    // console.log(this.notification.data.data.read);
    return this.notification.read;
  }

  notificationsCount() {
    let notification_count = 5;
    this.notificationService.notificationsCount()
      .subscribe(
        data => {
          notification_count = data['unread_notifications_count'];
        },
        error => {
        });
    return notification_count;
  }
}
