<div [nbSpinner]="loading && !f.phone.errors && !f.password.errors" class="login-wrapper">
  <h1 class="title" id="title">{{'Login' | translate}}</h1>
  <p class="sub-title">{{'Hello! Log in with your phone.' | translate}}</p>

  <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="example-form">

    <!--PHONE INPUT-->
    <mat-form-field appearance="outline" class="full-width">
      <ngx-mat-intl-tel-input #phone [enablePlaceholder]="true" [enableSearch]="true"
                              formControlName="phone" name="phone">
      </ngx-mat-intl-tel-input>
      <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
        <div *ngIf="f.phone.errors.required">{{'Sorry, phone is required' | translate}}</div>
      </div>
    </mat-form-field>

    <!--PASSWORD INPUT-->
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>{{'Password'| translate}}</mat-label>
      <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" matInput placeholder="Password"
             type="password">
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div>{{'Sorry, password is required'| translate }}</div>
      </div>
    </mat-form-field>

    <nb-alert *ngIf="submitted && f.phone.errors" accent="danger">phone is required</nb-alert>

    <button *ngIf="!loading || f.phone.errors || f.password.errors" class="login-btn">
      {{'Login'| translate}}
    </button>

  </form>
</div>

