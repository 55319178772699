import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';

import {ServerDataSource} from '@core/data/server.data-source';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {ToastService} from '@core/services/shared/toast.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {AppSettings} from '@config/app-settings.config';
import {HttpClient} from '@angular/common/http';
import {AttachmentStatusService} from '@core/enum/attachment-status.service';
import {AttachmentModel} from '@core/models/attachment.model';
import {FieldType} from '@core/enum/field-type.service';
import {FieldService} from '@core/services/field/field.service';

@Component({
  selector: 'ngx-service-fields-list',
  templateUrl: './service-fields-list.component.html',
})
export class ServiceFieldsListComponent implements OnInit, OnChanges {
  @Input() ServiceId: string;
  loading$ = new BehaviorSubject<boolean>(false);
  settings = {
    mode: 'external',
    hideSubHeader: false,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    noDataMessage: '',
    columns: {},
    columns_en: {
      id: {
        title: '#',
        width: '30px',
        type: 'number',
      },
      title: {
        title: 'title',
        type: 'string',
      },
      type: {
        title: 'type',
        type: 'number',
        editable: false,
        editor: {
          type: 'list',
          config: {
            list: '',
          },
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: this.fieldType.getFieldsList(),
          },
        },
        valuePrepareFunction: (value) => this.fieldType.toString(value),
      },
    },
    columns_ar: {
      id: {
        title: '#',
        width: '30px',
        type: 'number',
      },
      title_ar: {
        title: 'العنوان',
        type: 'string',
      },
      type: {
        title: 'النوع',
        type: 'number',
        editable: false,
        editor: {
          type: 'list',
          config: {
            list: '',
          },
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'اختر',
            list: this.fieldType.getFieldsList(),
          },
        },
        valuePrepareFunction: (value) => this.fieldType.toString(value),
      },
    },
    action: {},
  };
  source: ServerDataSource;
  orderAttachmentsList = new BehaviorSubject<AttachmentModel[]>([]);

  constructor(private _toastService: ToastService,
              private http: HttpClient,
              private router: Router,
              protected location: Location,
              private fieldType: FieldType,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog,
              private _fieldService: FieldService,
              private attachmentStatus: AttachmentStatusService,
              private appSettings: AppSettings,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    // Service's Field
    this.loading$.next(true);
    this.source = new ServerDataSource(this.http, this.appSettings, `services/${this.ServiceId}/fields`);
    this.source.onUpdateStarted().subscribe(e => {
      this.loading$.next(false);
    });
    //  Load current language
    this.updateColumns();
    this.translateService.onLangChange.subscribe(() => {
      this.updateColumns();
    });
  }

  updateColumns() {
    this.settings.noDataMessage = this.translateService.instant(marker('No Data Found'));
    this.settings.columns = this.settings['columns_' + this.translateService.currentLang];
    this.settings = Object.assign({}, this.settings);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  onRowSelect(event) {
    this.router.navigateByUrl('/fields/' + event.data.id);
  }
}
