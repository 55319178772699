import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class LanguageService {

  EN = 1;
  AR = 2;

  constructor() {
  }

  toString(statusId) {
    switch (statusId) {
      case this.EN:
        return 'EN';
      case this.AR:
        return 'AR';
    }
  }

  toLangServiceString(statusId) {
    switch (statusId) {
      case this.EN:
        return 'en';
      case this.AR:
        return 'ar';
    }
  }

  getLanguageList() {
    return [
      {
        value: this.EN,
        title: 'EN',
      },
      {
        value: this.AR,
        title: 'AR',
      },
    ];
  }

}
