import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  selector: 'ngx-status',
  template: `
    <nb-checkbox [(ngModel)]="checked" (checkedChange)="onCheckedChange()"></nb-checkbox>
  `,
})
export class RolesStatusCheckboxComponent implements ViewCell, OnInit {
  checked: boolean;
  roles: Array<number>;
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.checked = this.roles.indexOf(this.rowData.id) !== -1;
  }


  onCheckedChange() {
    const roleId = this.rowData.id;
    // get actual value because onCheckedChange give the last value
    const checked = !this.checked;
    if (checked) {
      // Add A new Role
      if (this.roles.indexOf(roleId) === -1) {
        this.roles.push(roleId);
      }
    } else {
      // Remove role
      this.roles = this.roles.filter(role => role !== roleId);
    }
    this.save.emit(this.roles);
  }
}
