import {Component, Injectable} from '@angular/core';
import {NotificationService} from '@core/services/notification/notification.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'ngx-infinite-list',
  templateUrl: 'notification-list.component.html',
  styleUrls: ['notification-list.component.scss'],
})

export class NotificationListComponent {


  notifications = [];
  loading = false;
  finish = false;
  pageToLoadNext = 1;
  pageSize = 10;

  constructor(private notificationService: NotificationService) {
  }

  loadNext(notifications) {
    if (this.loading || this.finish) {
      return;
    }
    this.loading = true;
    this.notificationService.notifications(this.pageToLoadNext, this.pageSize)
      .subscribe(nextNotifications => {
        if (nextNotifications['data'].length < this.pageSize) {
          this.finish = true;
        }
        notifications.push(...nextNotifications['data']);
        this.loading = false;
        this.pageToLoadNext++;
      });

  }

  markAllAsRead() {
    this.notificationService.markAllAsRead().pipe(first())
      .subscribe(
        data => {
        },
        error => {
        });

  }
}
