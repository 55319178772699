import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {StatusService} from '@core/services/status.service';

@Component({
  selector: 'ngx-status',
  template: `
    <nb-checkbox [checked]="checked" (checkedChange)="onCheckedChange()"></nb-checkbox>
  `,
})
export class StatusCheckboxComponent implements ViewCell, OnInit {
  checked: boolean;
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor(private statusService: StatusService) {
  }

  ngOnInit() {
    this.checked = this.statusService.isActive(this.value.toString());

  }

  onCheckedChange() {
    this.save.emit(this.rowData);
  }
}
