import {Injectable, OnDestroy} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import ar from '@assets/i18n/ar.json';
import en from '@assets/i18n/en.json';
import {NbLayoutDirection, NbLayoutDirectionService} from '@nebular/theme';
import {AuthService} from '@core/services/auth.service';
import {LanguageService} from '@core/enum/language.service';
import {first} from 'rxjs/operators';
import moment from 'moment';

const languageKey = 'language';

@Injectable({
  providedIn: 'root',
})

export class I18nService implements OnDestroy {

  defaultLanguage!: string;
  supportedLanguages = [
    {title: 'English', value: 'en'},
    {title: 'العربية', value: 'ar'},
  ];
  private langChangeSubscription!: Subscription;

  constructor(
    private translateService: TranslateService,
    private directionService: NbLayoutDirectionService,
    private authService: AuthService,
    private nbLayoutDirectionService: NbLayoutDirectionService,
    private userLanguage: LanguageService,
  ) {
  }

  /**
   * Gets the current language.
   *
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   *
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    language = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
    const supportedLanguagesValues = this.supportedLanguages.map(lang => lang.value);
    let isSupportedLanguage = supportedLanguagesValues.includes(language);
    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = supportedLanguagesValues.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }
    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
      //   switch (this.authService.currentUserValue.language) {
      //     case this.userLanguage.EN:
      //       this.translateService.use('en');
      //       this.nbLayoutDirectionService.setDirection(NbLayoutDirection.LTR);
      //       break;
      //     case 2:
      //       this.translateService.use('ar');
      //       this.nbLayoutDirectionService.setDirection(NbLayoutDirection.RTL);
      //       break;
      //   }
    }
    this.translateService.use(language);
  }

  get isRTL(): boolean {
    const rtlLanguages = ['ar'];
    return Boolean(
      rtlLanguages
        .find(supportedLanguage => supportedLanguage.startsWith(this.language)) || '',
    );
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   */
  init() {
    // Embed languages to avoid extra HTTP requests
    this.translateService.setTranslation('ar', ar);
    this.translateService.setTranslation('en', en);
    let lang = 'en';
    try {
      lang = this.userLanguage.toLangServiceString(this.authService.currentUser.me.language);
    } catch (e) {
    }
    this.handleLanguageChange();

    this.langChangeSubscription = this.translateService.onLangChange
      .subscribe((event: LangChangeEvent) => {
        localStorage.setItem(languageKey, event.lang);
        this.handleLanguageChange();
      });

    // selecting default language
    this.language = this.translateService.currentLang;

  }

  private handleLanguageChange() {
    moment.locale(this.language);
    this.directionService.setDirection(this.isRTL ? NbLayoutDirection.RTL : NbLayoutDirection.LTR);
  }

  changeUserLanguage(language) {
    this.authService.updateProfileLanguage(this.getLang(language))
      .pipe(first())
      .subscribe(
        data => {
          // User Details
        },
        error => {
        });
  }

  /**
   * Cleans up language change subscription.
   */
  ngOnDestroy() {
  }

  getLang(lang: string) {
    if (lang === 'ar') {
return 2;
}
    if (lang === 'en') {
return 1;
}
  }

}
