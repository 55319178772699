<!-- image -->
<div class="form-group row">
  <div class="col-sm-3 col-form-label">
    <label class="label" for="image">{{title | translate}}</label>
  </div>
  <div class="col-sm-3 preview">
    <img *ngIf="imgUrl; else noImage" [src]="imgUrl" height="150" id="file-preview" style="margin: 3px;"
         width="200px">
    <ng-template #noImage>No Image</ng-template>
  </div>

  <div class="col-sm-3">
    <div id="image">
      <div class="user_image_container">
        <button (click)="fileInput.click()" [disabled]="disActiveValue" class="btn btn-primary" type="button">
          <nb-icon icon="image-outline"></nb-icon>
          <span>{{"Upload Image  " | translate}}</span>
          <input #fileInput (change)="uploadImage(fileInput)" accept="image/*" style="display: none;"
                 type="file">
        </button>
      </div>
    </div>
  </div>

</div>

