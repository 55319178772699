import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {LayoutService} from '@core/utils';
import {map, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {RippleService} from '@core/utils/ripple.service';
import {Router} from '@angular/router';
import {AuthService} from '@core/services/auth.service';
import {I18nService} from '@core/services/i18n.service';
import {environment} from '@env';
import {TranslateService} from '@ngx-translate/core';
import {
  NotificationListComponent,
} from '@core/components/notification-list/notification-list.component';
import {NotificationService} from '@core/services/notification/notification.service';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly = false;
  user: any;
  notification_count: string = null;
  currentUser: any;
  currentTheme = 'default';
  notificationsComponent = NotificationListComponent;
  userMenu = [];
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private rippleService: RippleService,
    private authService: AuthService,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private notificationService: NotificationService,
    private router: Router) {
    this.materialTheme$ = this.themeService.onThemeChange()
      .pipe(map(theme => {
        const themeName: string = theme?.name || '';
        return themeName.startsWith('material');
      }));

    this.menuService.onItemClick()
      .subscribe((event) => {
        this.onContextItemSelection(event.item.title);
      });
  }

  notificationsCount() {
    this.notificationService.notificationsCount()
      .subscribe(
        response => {
          this.notification_count = response['data']['unread_notifications_count'];
        },
        error => {
        });
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages() {
    return this.i18nService.supportedLanguages;
  }

  get link() {
    return environment.baseUrl;
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUser.me;

    this.currentTheme = this.themeService.currentTheme;
    this.updateUserMenu();

    this.translateService.onLangChange.subscribe(() => {
      this.updateUserMenu();
    });

    this.notificationsCount();
    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
        this.rippleService.toggle(themeName?.startsWith('material'));
      });
  }

  private updateUserMenu() {
    this.userMenu = [
      {title: this.translateService.instant(marker('Profile'))},
      {title: this.translateService.instant(marker('Log out'))}];
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  changeLanguage(language) {
    this.i18nService.language = language;
    this.i18nService.changeUserLanguage(language);
  }

  onContextItemSelection(title) {
    if (title === 'Log out' || title === 'تسجيل خروج') {
      this.authService.logout();
    } else if (title === 'Profile' || title === 'الحساب') {
      this.router.navigate(['profile']);
    }
  }
}
