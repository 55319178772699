<nb-card class="infinite-cards margin-bottom-0">
  <nb-card-header>{{"Notifications" | translate}}
    <button nbButton size="small" (click)="markAllAsRead()">
      <nb-icon icon="checkmark-outline"></nb-icon>
      {{"Mark all as read"  | translate}}</button>
  </nb-card-header>
  <nb-list
    nbInfiniteList
    [threshold]="500"
    (bottomThreshold)="loadNext(notifications)">
    <nb-list-item *ngFor="let notification of notifications">
      <ngx-notification [notification]="notification"></ngx-notification>
    </nb-list-item>
    <nb-list-item *ngIf="loading" [nbSpinner]="loading">
    {{"Loading"  | translate}}
    </nb-list-item>
  </nb-list>
</nb-card>
